<template>
    <div class="register">
        <div class="registerL">
            <ent-explain-vue />
        </div>
        <div class="registerR">
            <div class="register-one" v-show="sep==0">
                <h3 class="title">欢迎使用营销人</h3>
                <input-box-vue label="" placeholder="请输入您的手机号" v-model="personal.mobile" inputType="tel" class="mb40"/>
                <p class="explain-check">
                    <label>
                        <input type="checkbox" v-model="personal.explainCheck">
                        <span>我已阅读并同意营销人《<i>服务协议</i>》和《<i>隐私政策</i>》</span>
                    </label>
                </p>
                <button class="next-btn" type="button" @click="nextFn(1)" style="margin-top: 8px;" v-if="isBtn.isTelBtn">下一步</button>
                <button class="not-next-btn" type="button" style="margin-top: 8px;" v-else>下一步</button>
            </div>
            <div class="register-tow" v-show="sep==1">
                <div class="previous" @click="nextFn(0)">&lt;返回重新输入手机号</div>
                <h3 class="title">验证手机号</h3>
                <p class="code-tishi" v-if="isBtn.isCodeTs">请输入发送至<span>{{personal.tel}}</span>的验证码，有效期十分钟。如未收到，请尝试重新获取验证码。</p>
                <p class="code-cuowu" v-else>验证码错误，请重新输入。</p>
                <code-vue v-model="personal.code"/>
                <span class="getCode1" v-if="isBtn.isSecond">{{second}}秒之后可重新获取验证码</span>
                <span class="getCode2" v-else @click="getCode">重新获取验证码</span>
                <button class="next-btn" type="button" @click="nextFn(2)" style="margin-top: 88px;" v-if="isBtn.isCodeBtn">下一步</button>
                <button class="not-next-btn" type="button" style="margin-top: 88px;" v-else>下一步</button>
            </div>
            <div class="register-three" v-show="sep == 2">
                <h3 class="title">设置您的密码和姓名</h3>
                <password-vue label="新密码" v-model="personal.pass" isGrade="1" class="mb40"/>
                <input-box-vue label="姓名" v-model="personal.name" placeholder="请输入您的姓名"/>
                <button class="next-btn" type="button" @click="nextFn(3)" style="margin-top: 68px;" v-if="isBtn.isNewPass">下一步</button>
                <button class="not-next-btn" type="button" style="margin-top: 68px;" v-else>下一步</button>
            </div>
            <div class="register-four" v-show="sep == 3">
                <h3 class="title">创建您的企业</h3>
                <input-box-vue label="企业名称" v-model="personal.company_name" placeholder="请输入企业名称" class="mb40"/>
                <select-m-vue
                    label="所属行业"
                    v-model="personal.industry_uuid"
                    placeholder="请选择所属行业"
                    s class="mb40"
                    :data="industrysArr"
                />
                <select-m-vue
                    label="人员规模"
                    v-model="personal.staff_scale_uuid"
                    placeholder="请选择人员规模"
                    class="mb40"
                    :data="staffScaleArr"
                />
                <button class="next-btn" type="button" @click="nextFn(4)">完成</button>
            </div>
        </div>
    </div>
</template>

<script>
    import codeVue from '../../components/code'
    import passwordVue from '../../components/password'
    import inputBoxVue from '../../components/inputBox'
    import EntExplainVue from './com/EntExplain.vue'
    import selectMVue from '@/components/selectM.vue'
    import { mapState } from 'vuex'
    export default {
        name: "register",
        components:{
            codeVue,     // 验证码组件
            passwordVue, // 密码组件
            inputBoxVue, // input组件
            EntExplainVue,
            selectMVue,
        },
        computed:{
            ...mapState([
                'staffScaleArr', // 人员规模
                'industrysArr',  // 所属行业
            ]),
        },
        data(){
            return {
                'sep': 0,                // 第几步
                'second': 60,            // 获取验证码秒数
                'personal':{
                    'explainCheck':false,// 说明勾选
                    'company_name':'',        // 企业名称
                    'industry_uuid':'',       // 所属行业
                    'staff_scale_uuid':'', // 人员规模
                    'mobile':'',            // 手机号
                    'name':'',           // 姓名
                    'code':'',           // 验证码
                    'pass':'',           // 密码
                },
                'isBtn':{
                    'isTelBtn':false, // 手机号下一步按钮
                    'isCodeBtn':false,
                    'isCodeTs':true,
                    'isNewPass':false,
                    'isEnt':false,
                    'isSecond':true,
                },
                'staffScale':[], // 人员规模
                'industrys':[], // 所属行业
            }
        },
        watch:{
            'personal':{
                handler(newVal,val){
                    if(newVal.mobile.length == 11){
                        this.isBtn.isTelBtn = true;
                    }else{
                        this.isBtn.isTelBtn = false;
                    }
                    if(newVal.code.length  == 6){
                        this.isBtn.isCodeBtn = true;
                    }else{
                        this.isBtn.isCodeBtn = false;
                    }
                    if(newVal.pass.length < 8 || newVal.name == ''){
                        this.isBtn.isNewPass = false;
                    }else{
                        this.isBtn.isNewPass = true;
                    }
                    if(newVal.company_name == '' || newVal.industry_uuid == '' || newVal.staff_scale_uuid == ''){
                        this.isEnt = false;
                    }else{
                        this.isEnt = true;
                    }
                },
                deep:true,
            }
        },
        methods:{
            // 下一步
            nextFn(index){
                // 完成手机填写下一步
                if(index == 1){
                    if(this.personal.mobile == ''){ return this.$message({ message: '请输入手机号', type: 'warning' }); }
                    if(!this.$cm.checkPhone(this.personal.mobile)){ return this.$message({ message: '请输入正确手机号', type: 'warning' }); }
                    if(!this.personal.explainCheck){ return this.$message({ message: '请勾选《服务协议》和《隐私政策》', type: 'warning'}); }
                    this.getCode(index)
                    return false;
                }
                // 完成验证码下一步
                if(index == 2){
                    if(this.personal.code == ''){ return this.$message({ message: '请输入验证码', type: 'warning' }); }
                    if(this.personal.code.length != 6){ return this.$message({ message: '请输入正确的验证码', type: 'warning' }); }
                    this.checkCaptcha(index);
                    return false;
                }
                // 完成设置密码下一步
                if(index == 3){
                    if(this.personal.pass == ''){ return this.$message({ message: '请输入新密码', type: 'warning' }); }
                    if(this.personal.pass.length < 8 ){ return this.$message({ message: '密码长度不合法，请输入8-20个的新密码', type: 'warning' }); }
                    if(this.personal.name == ''){ return this.$message({ message: '请输入您的姓名', type: 'warning' }); }
                    this.sep = index;
                    return false;
                }
                //
                if(index == 4){
                    if(this.personal.company_name == ''){ return this.$message({ message: '请输入您的企业名称', type: 'warning' }); }
                    if(this.personal.industry_uuid == ''){ return this.$message({ message: '请选择您所属行业', type: 'warning' }); }
                    if(this.personal.staff_scale_uuid == ''){ return this.$message({ message: '请选择您的人员规模', type: 'warning' }); }
                    this.create();
                    return false;
                }
            },
            getCode(index){
                this.axios('post',`${this.$phpBaseURL}auth/v1/register/captcha`,{
                    mobile:this.personal.mobile,
                }).then(res=>{
                    if(res.code == 1){
                        this.$message({ message: '获取验证码成功', type: 'success' });
                        this.secondTime();
                        if(index){
                            this.sep = index;
                        }
                    } else if(res.code == 2){
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    }else{
                        this.$message({ message: res.msg, type: 'warning' });
                    }
                })
            },
            secondTime(){
                let that = this;
                let time =null
                time = setInterval(() => {
                    if(that.second != 0){
                        that.second--;
                    }else{
                        this.isBtn.isSecond = false;
                        clearInterval(time)
                    }
                },1000)
            },
            // 注册函数
            create(){
                this.axios('post',`${this.$phpBaseURL}auth/v1/register/create`,{
                    mobile:this.personal.mobile,
                    captcha:this.personal.code,
                    admin_password:this.personal.pass,
                    admin_name:this.personal.name,
                    company_name:this.personal.company_name,
                    industry_uuid:this.personal.industry_uuid,
                    staff_scale_uuid:this.personal.staff_scale_uuid,
                }).then(res =>{
                    if(res.code == 1){
                        this.$message({ message: '注册成功', type: 'success' });
                        this.$router.push('/login')
                    } else if(res.code == 2){
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    } else{
                        this.$message({ message: res.msg, type: 'warning' });
                    }
                })
            },
            // 验证码校对
            checkCaptcha(index){
                this.axios('post',`${this.$phpBaseURL}auth/v1/register/checkCaptcha`,{
                    mobile:this.personal.mobile,
                    captcha:this.personal.code,
                }).then(res => {
                    if(res.code == 1){
                        this.sep = index;
                    } else if(res.code == 2){
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    }else{
                        this.isBtn.isCodeTs = false;
                        this.isBtn.isSecond = false;
                    }
                    return false;
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .register{
        width: 100%;
        height: 100%;
        display: flex;
        .registerL,.registerR{
            width: 50%;
            box-sizing: border-box;
        }
        .title{
            font-size:28px;
            font-weight:500;
            color:rgba(25,39,68,1);
            line-height:40px;
            margin-bottom: 32px;
        }


        .registerL{background: #f5f5f5}
        .registerR{
            padding: 0 150px;
            .register-one{
                padding-top: 100px;
                .explain-check{
                    margin-top: 100px;
                    label{
                        input{
                            margin-right: 8px;
                        }
                    }
                    span{
                        font-size:12px;
                        font-weight:400;
                        color:rgba(120,128,144,1);
                        line-height:17px;
                        i{
                            font-size:12px;
                            font-weight:400;
                            color:#4786FF;
                            line-height:17px;
                        }
                    }
                }
            }
            .register-tow{
                padding-top: 115px;
                .previous{
                    font-size:14px;
                    font-weight:400;
                    color:rgba(120,128,144,1);
                    line-height:20px;
                    cursor: pointer;
                }
                .code-tishi{
                    font-size:16px;
                    font-weight:400;
                    color:rgba(120,128,144,1);
                    line-height:22px;
                    margin-bottom: 24px;
                    span{
                        color: #192744;
                        font-size: 20px;
                    }
                }
                .code-cuowu{
                    font-size:16px;
                    font-weight:400;
                    color:rgba(254,102,100,1);
                    line-height:22px;
                    margin-bottom: 54px;
                }
                .getCode1{
                    font-size:18px;
                    font-weight:400;
                    color:rgba(120,128,144,1);
                    line-height:25px;
                }
                .getCode2{
                    font-size:16px;
                    font-weight:400;
                    color:rgba(71,134,255,1);
                    line-height:22px;
                }
            }
            .register-three,.register-four{
                padding-top: 100px;
            }
        }
    }
    @media screen and (max-width: 980px){
        .register{
            flex-flow: column;
            .registerL{
                display: none;
            }
            .registerR{
                width: 100%;
                padding: 0 20px;
            }
        }
    }
</style>