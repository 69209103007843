<template>
    <div class="selectM">
        <label v-if="label!=''">{{label}}</label>
        <el-select v-model="value" :placeholder="placeholder" :disabled="disabled">
            <el-option
                    v-for="item in data"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
            </el-option>
        </el-select>
    </div>
</template>

<script>
    export default {
        name: "selectM",
        props:{
            'label':{ // lebel文字
                type:String,
                default:''
            },
            'placeholder':{ // placeholder文字
                type:String,
                default:'请选择'
            },
            'data':{ // 下拉列表 需要指定格式
                type:Array,
                default:()=>[]
            },
            'iptVal':{// 父级传来的默认值
                type:String,
                default:''
            },
            'disabled':{
                type:Boolean,
                default:false
            }
        },
        data(){
            return {
                'value': '',
                'options':[],
            }
        },
        watch:{
            value(newVal){
                this.$emit('input',newVal)
            },
            iptVal(val){
                this.value = val;
            },
        },
    }
</script>

<style scoped lang="scss">
    .selectM{
        width: 100%;
        label{
            display: block;
            font-size: 14px;
            color: #788090;
            margin-bottom: 8px;
        }
        /deep/ .el-select{
            width: 100%;
        }
    }
</style>